<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal custom-fields show" id="new-user-modal" style="display: block">
            <div class="modal-dialog modal-dialog-centered modal-md" style="max-width: 650px;" role="document">
                <div class="modal-content" v-on-click-away="closeModal">
                    <div class="modal-header">
                        <div class="d-flex content-center">
                            <div>
                                <p class="text-danger">{{ $t('Package limit exceeded') }}</p>
                            </div>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click.prevent="closeModal()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <div style="width: 90px; margin: 0 auto;">
                                <svg viewBox="0 0 943 943" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M471.46 942.92C731.84 942.92 942.92 731.84 942.92 471.46C942.92 211.08 731.84 0 471.46 0C211.08 0 0 211.08 0 471.46C0 731.84 211.08 942.92 471.46 942.92Z" fill="#597DFC"/>
                                    <path d="M494.48 603.26C466.73 603.26 444.23 580.76 444.23 553.01V215.23C444.23 187.48 466.73 164.98 494.48 164.98C522.23 164.98 544.73 187.48 544.73 215.23V553.01C544.73 580.76 522.23 603.26 494.48 603.26Z" fill="#3961EF"/>
                                    <path d="M547.307 709.951C554.197 680.771 536.128 651.53 506.948 644.64C477.768 637.75 448.527 655.819 441.637 684.999C434.747 714.179 452.816 743.42 481.996 750.31C511.176 757.2 540.417 739.131 547.307 709.951Z" fill="#3961EF"/>
                                    <path d="M466.3 595.25C438.55 595.25 416.05 572.75 416.05 545V207.22C416.05 179.47 438.55 156.97 466.3 156.97C494.05 156.97 516.55 179.47 516.55 207.22V545C516.55 572.75 494.06 595.25 466.3 595.25Z" fill="white"/>
                                    <path d="M466.3 743.7C496.284 743.7 520.59 719.394 520.59 689.41C520.59 659.426 496.284 635.12 466.3 635.12C436.316 635.12 412.01 659.426 412.01 689.41C412.01 719.394 436.316 743.7 466.3 743.7Z" fill="white"/>
                                    <path d="M471.46 910.1C412.25 910.1 354.8 898.5 300.72 875.63C248.49 853.54 201.58 821.91 161.29 781.63C121.01 741.35 89.3801 694.44 67.2901 642.2C44.4201 588.12 32.8201 530.67 32.8201 471.46C32.8201 412.25 44.4201 354.8 67.2901 300.72C89.3801 248.49 121.01 201.58 161.29 161.29C201.57 121.01 248.48 89.3799 300.72 67.2899C354.8 44.4199 412.25 32.8199 471.46 32.8199C530.67 32.8199 588.12 44.4199 642.2 67.2899C694.43 89.3799 741.34 121.01 781.63 161.29C821.91 201.57 853.54 248.48 875.63 300.72C898.5 354.8 910.1 412.25 910.1 471.46C910.1 530.67 898.5 588.12 875.63 642.2C853.54 694.43 821.91 741.34 781.63 781.63C741.35 821.91 694.44 853.54 642.2 875.63C588.12 898.5 530.68 910.1 471.46 910.1ZM471.46 42.5999C234.99 42.5999 42.6001 234.98 42.6001 471.46C42.6001 707.94 234.99 900.32 471.46 900.32C707.93 900.32 900.32 707.93 900.32 471.46C900.32 234.99 707.94 42.5999 471.46 42.5999Z" fill="white"/>
                                </svg>
                            </div>
                            <h4 class="text-danger my-3">{{$t('Opps')}}…</h4>
                            <p>{{$t('Your package limit exceeded, please upgrade to suitable package.')}}</p>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <a href="#" class="button semi-button-info text-capitalize"
                           @click.prevent="closeModal()">{{ $t("back") }}</a>
                        <router-link :to="{name:'my-account'}" class="button semi-button-success text-capitalize">{{
                            $t("upgrade") }}
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    methods: {
        closeModal() {
            this.$emit('closePackageLimitModal');
        },
    },
};
</script>
